import React from 'react'
import {BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './views/Home'
import AnimalFarm from './views/AnimalFarm'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/animal-farm" element={<AnimalFarm />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
