export const filterDefaultKeys = [
  'chapter',
  'animal',
  'background',
  'body',
  'clothes',
  'expression',
  'eyewear',
  'headwear'
]

export const filterDefaults = {
  chapter: 'All',
  animal: 'All',
  background: 'All',
  body: 'All',
  clothes: 'All',
  expression: 'All',
  eyewear: 'All',
  headwear: 'All',
  rankGroup: 'All'
}

export const allChapters = [
  {id: 'All', label: 'All'},
  {id: 'Chapter 1', label: 'Chapter 1'},
  {id: 'Chapter 2', label: 'Chapter 2'},
  {id: 'Chapter 3', label: 'Chapter 3'},
  {id: 'Chapter 4', label: 'Chapter 4'},
  {id: 'Chapter 5', label: 'Chapter 5'},
  {id: 'Chapter 6', label: 'Chapter 6'},
  {id: 'Chapter 7', label: 'Chapter 7'},
  {id: 'Chapter 8', label: 'Chapter 8'},
  {id: 'Chapter 9', label: 'Chapter 9'},
  {id: 'Chapter 10', label: 'Chapter 10'}
]

export const allAnimals = [
  {id: 'All', label: 'All'},
  {id: 'Pig', label: 'Pig'},
  {id: 'Horse', label: 'Horse'},
  {id: 'Chicken', label: 'Chicken'}
]

export const allBackgrounds = [
  {id: 'All', label: 'All'},
  {id: 'Bright', label: 'Bright'},
  {id: 'Camo', label: 'Camo'},
  {id: 'Death', label: 'Death'},
  {id: 'Flesh', label: 'Flesh'},
  {id: 'Graf', label: 'Graf'},
  {id: 'Heaven', label: 'Heaven'},
  {id: 'Hell', label: 'Hell'},
  {id: 'Money', label: 'Money'},
  {id: 'Purple', label: 'Purple'},
  {id: 'Rave', label: 'Rave'},
  {id: 'Sea', label: 'Sea'},
  {id: 'Stars', label: 'Stars'},
  {id: 'Virtual', label: 'Virtual'},
  {id: 'Wall', label: 'Wall'},
  {id: 'Woodland', label: 'Woodland'}

]

export const allBodies = [
  {id: 'All', label: 'All'},
  {id: 'Blue', label: 'Blue'},
  {id: 'Blue glam', label: 'Blue glam'},
  {id: 'Brown', label: 'Brown'},
  {id: 'Brown glam', label: 'Brown glam'},
  {id: 'Ghost', label: 'Ghost'},
  {id: 'Ghost glam', label: 'Ghost glam'},
  {id: 'Green', label: 'Green'},
  {id: 'Green glam', label: 'Green glam'},
  {id: 'Grey', label: 'Grey'},
  {id: 'Grey glam', label: 'Grey glam'},
  {id: 'Orange', label: 'Orange'},
  {id: 'Orange glam', label: 'Orange glam'},
  {id: 'Pink', label: 'Pink'},
  {id: 'Pink glam', label: 'Pink glam'},
  {id: 'Robot', label: 'Robot'},
  {id: 'Robot glam', label: 'Robot glam'},
  {id: 'Tattoo', label: 'Tattoo'}
]

export const allClothes = [
  {id: 'All', label: 'All'},
  {id: 'Astronaut', label: 'Astronaut'},
  {id: 'Banker', label: 'Banker'},
  {id: 'Beatnik', label: 'Beatnik'},
  {id: 'Beatnik bling', label: 'Beatnik bling'},
  {id: 'Beatnik ranch', label: 'Beatnik ranch'},
  {id: 'Cobain', label: 'Cobain'},
  {id: 'Cobain bling', label: 'Cobain bling'},
  {id: 'Cobain ranch', label: 'Cobain ranch'},
  {id: 'Dealer', label: 'Dealer'},
  {id: 'Dealer bling', label: 'Dealer bling'},
  {id: 'Diver', label: 'Diver'},
  {id: 'Diver bling', label: 'Diver bling'},
  {id: 'Doctor', label: 'Doctor'},
  {id: 'Doctor bling', label: 'Doctor bling'},
  {id: 'Eddie', label: 'Eddie'},
  {id: 'Eddie bling', label: 'Eddie bling'},
  {id: 'Eddie ranch', label: 'Eddie ranch'},
  {id: 'Fonz', label: 'Fonz'},
  {id: 'Fonz bling', label: 'Fonz bling'},
  {id: 'Fonz ranch', label: 'Fonz ranch'},
  {id: 'French', label: 'French'},
  {id: 'French bling', label: 'French bling'},
  {id: 'French ranch', label: 'French ranch'},
  {id: 'Jobs', label: 'Jobs'},
  {id: 'Jobs bling', label: 'Jobs bling'},
  {id: 'Jobs ranch', label: 'Jobs ranch'},
  {id: 'Lawyer', label: 'Lawyer'},
  {id: 'Lumberjack', label: 'Lumberjack'},
  {id: 'Mcqueen', label: 'Mcqueen'},
  {id: 'Mcqueen bling', label: 'Mcqueen bling'},
  {id: 'Mcqueen ranch', label: 'Mcqueen ranch'},
  {id: 'Military', label: 'Military'},
  {id: 'Military ranch', label: 'Military ranch'},
  {id: 'None', label: 'None'},
  {id: 'Police', label: 'Police'},
  {id: 'Polo', label: 'Polo'},
  {id: 'Polo ranch', label: 'Polo ranch'},
  {id: 'Prison', label: 'Prison'},
  {id: 'Prison bling', label: 'Prison bling'},
  {id: 'Prison ranch', label: 'Prison ranch'},
  {id: 'Repressed', label: 'Repressed'},
  {id: 'Snooker', label: 'Snooker'},
  {id: 'Snooker bling', label: 'Snooker bling'},
  {id: 'Snooker ranch', label: 'Snooker ranch'},
  {id: 'Spring', label: 'Spring'},
  {id: 'Spring bling', label: 'Spring bling'},
  {id: 'Summer', label: 'Summer'},
  {id: 'Summer bling', label: 'Summer bling'},
  {id: 'Swimmer', label: 'Swimmer'},
  {id: 'Swimmer bling', label: 'Swimmer bling'},
  {id: 'Teen', label: 'Teen'},
  {id: 'Teen bling', label: 'Teen bling'},
  {id: 'Teen ranch', label: 'Teen ranch'},
  {id: 'Vicar', label: 'Vicar'},
  {id: 'Vicar bling', label: 'Vicar bling'},
  {id: 'Winter', label: 'Winter'},
  {id: 'Winter ranch', label: 'Winter ranch'},
  {id: 'Writer', label: 'Writer'},
  {id: 'Writer bling', label: 'Writer bling'}
]

export const allExpressions = [
  {id: 'All', label: 'All'},
  {id: 'Beard', label: 'Beard'},
  {id: 'Cigarette', label: 'Cigarette'},
  {id: 'Considered', label: 'Considered'},
  {id: 'Crazy', label: 'Crazy'},
  {id: 'Dead', label: 'Dead'},
  {id: 'Eager', label: 'Eager'},
  {id: 'Friendly', label: 'Friendly'},
  {id: 'Frustrated', label: 'Frustrated'},
  {id: 'Greed', label: 'Greed'},
  {id: 'Happy', label: 'Happy'},
  {id: 'Kneiving', label: 'Kneiving'},
  {id: 'Manic', label: 'Manic'},
  {id: 'Menace', label: 'Menace'},
  {id: 'Moaning', label: 'Moaning'},
  {id: 'Overdosed', label: 'Overdosed'},
  {id: 'Pondering', label: 'Pondering'},
  {id: 'Shifty', label: 'Shifty'},
  {id: 'Shock', label: 'Shock'},
  {id: 'Spaced', label: 'Spaced'},
  {id: 'Stoned', label: 'Stoned'},
  {id: 'Stubble', label: 'Stubble'},
  {id: 'Tired', label: 'Tired'},
  {id: 'Unsure', label: 'Unsure'},
  {id: 'Vampire', label: 'Vampire'},
  {id: 'Yelling', label: 'Yelling'},
  {id: 'Zombie', label: 'Zombie'}
]

export const allEyewears = [
  {id: 'All', label: 'All'},
  {id: 'Batgirl', label: 'Batgirl'},
  {id: 'Batman', label: 'Batman'},
  {id: 'Cobain', label: 'Cobain'},
  {id: 'Glasses', label: 'Glasses'},
  {id: 'Lolita', label: 'Lolita'},
  {id: 'Monocle', label: 'Monocle'},
  {id: 'None', label: 'None'},
  {id: 'Patch', label: 'Patch'},
  {id: 'Snorkel', label: 'Snorkel'},
  {id: 'Robot', label: 'Robot'},
  {id: 'Sunglasses', label: 'Sunglasses'},
  {id: 'Warhol', label: 'Warhol'}
]

export const allHeadwears = [
  {id: 'All', label: 'All'},
  {id: 'Artist', label: 'Artist'},
  {id: 'Astronaut', label: 'Astronaut'},
  {id: 'Beret', label: 'Beret'},
  {id: 'Betty', label: 'Betty'},
  {id: 'Bowler', label: 'Bowler'},
  {id: 'Britpop', label: 'Britpop'},
  {id: 'Cap', label: 'Cap'},
  {id: 'Elvis', label: 'Elvis'},
  {id: 'Fisherman', label: 'Fisherman'},
  {id: 'Gamer', label: 'Gamer'},
  {id: 'Halo', label: 'Halo'},
  {id: 'Headphones', label: 'Headphones'},
  {id: 'Hippy', label: 'Hippy'},
  {id: 'Horns', label: 'Horns'},
  {id: 'None', label: 'None'},
  {id: 'Police', label: 'Police'},
  {id: 'Veronica', label: 'Veronica'},
  {id: 'Wayfarer', label: 'Wayfarer'},
  {id: 'Zeldman', label: 'Zeldman'}
]

export const allRankGroups = [
  {id: 'All', label: 'All'},
  {id: 100, label: 100},
  {id: 200, label: 200},
  {id: 300, label: 300},
  {id: 400, label: 400},
  {id: 500, label: 500},
  {id: 600, label: 600},
  {id: 700, label: 700},
  {id: 800, label: 800},
  {id: 900, label: 900},
  {id: 1000, label: 1000},
  {id: 1100, label: 1100},
  {id: 1200, label: 1200},
  {id: 1300, label: 1300},
  {id: 1400, label: 1400},
  {id: 1500, label: 1500},
  {id: 1600, label: 1600},
  {id: 1700, label: 1700},
  {id: 1800, label: 1800},
  {id: 1900, label: 1900},
  {id: 2000, label: 2000},
  {id: 2100, label: 2100},
  {id: 2200, label: 2200},
  {id: 2300, label: 2300},
  {id: 2400, label: 2400},
  {id: 2500, label: 2500},
  {id: 2600, label: 2600},
  {id: 2700, label: 2700},
  {id: 2800, label: 2800},
  {id: 2900, label: 2900},
  {id: 3000, label: 3000}
]
