import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


const HomeGoals = ({contractAddress}) => {
  return (
    <Container className="px-3 px-md-5 py-5 text-left">
      <Row className="helvetica-heading h1 px-3 text-left">PROVENANCE & CONTRACTS</Row>
      <Row className="mt-3 px-3">
        <div className="helvetica-heading h4 mt-3 px-0">TEZOS FARMATION POLL & MINT CONTRACT</div>
        <div className="helvetica-body mt-2 px-0 text-break">
          <a href="https://tzstats.com/KT1RPjHmjNFjWQfWhGq7ee48ezGmEvECNRwP" target="_blank" rel="noreferrer">
            KT1RPjHmjNFjWQfWhGq7ee48ezGmEvECNRwP
          </a>
        </div>
        <div className="helvetica-heading h4 mt-3 px-0">TEZOS FARMATION NFT CONTRACT</div>
        <div className="helvetica-body mt-2 px-0 text-break">
          <a href="https://tzstats.com/KT1CybmNEbNH4rRK8Ba3MXRnf2gg6FLZXccc" target="_blank" rel="noreferrer">
            KT1CybmNEbNH4rRK8Ba3MXRnf2gg6FLZXccc
          </a>
        </div>
        <div className="helvetica-heading h4 mt-3 px-0">PROVENANCE HASH</div>
        <div className="helvetica-body mb-5 mt-2 px-0 text-break">
          <a href="https://cloudflare-ipfs.com/ipfs/QmaspjWFSvpkWFaXTaD72M47uKkpHHW1KSRVDH9oZPu4r5" target="_blank" rel="noreferrer">
            QmaspjWFSvpkWFaXTaD72M47uKkpHHW1KSRVDH9oZPu4r5
          </a>
        </div>
      </Row>
    </Container>
  )
}

export default HomeGoals
