import {useState} from 'react'
import nextId from "react-id-generator"

import Collapse from 'react-bootstrap/Collapse'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons'


const Question = ({children, number, question, open, setOpen}) => {
  var htmlId = nextId()
  return (
    <div className="w-100 mb-2">
      <div className="d-flex" onClick={() => setOpen(!open)} aria-controls={htmlId} aria-expanded={open}>
        <div className="faq-question-button"><b>{number}</b></div>
        <div className="faq-question helvetica-body pl-3 pr-2">{question}</div>
        <div className="faq-question-button ml-auto">
          {open ?
            <FontAwesomeIcon icon={faMinus} />
            :
            <FontAwesomeIcon icon={faPlus} />
          }
        </div>
      </div>
      <Collapse in={open}>
        <div className="p-3 helvetica-body" id={htmlId}>
          {children}
        </div>
      </Collapse>
    </div>
  )
}


const HomeFaq = ({...props}) => {
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open5, setOpen5] = useState(false)
  const [open6, setOpen6] = useState(false)
  const [open7, setOpen7] = useState(false)
  const [open8, setOpen8] = useState(false)
  const [open9, setOpen9] = useState(false)
  const [open10, setOpen10] = useState(false)
  const [open11, setOpen11] = useState(false)
  return (
    <Container className="px-3 px-md-5 py-5">
      <div className="faq">
        <div className="faq-inner p-3 p-md-5">
          <Row className="helvetica-heading h1 m-0 mb-3">THE QUESTIONS</Row>
          <Question number='1' question="When and how much is the next minting, or 'Barn Opening' as you have coined it?" open={open1} setOpen={setOpen1}>
            The next Barn Opening (minting) is actually our first, and will
            begin at 14:00 GMT, on the Ides of March 2022 (15th March). The cost
            to mint an NFT will be 10 XTZ if you have participated in our poll,
            or 15 XTZ if you haven't (so make sure you vote in our poll first).
          </Question>
          <Question number='2' question="Do you have a whitelist, and how do I get included on the list?" open={open2} setOpen={setOpen2}>
            We won't be having a whitelist as we are trying to embody our
            'All animals are equal' mantra.
          </Question>
          <Question number='3' question="Why do I have to wait 7 days before you reveal the NFT artwork and other metadata?" open={open3} setOpen={setOpen3}>
            As we have introduced the use of text from George Orwell's Animal
            Farm for the titles for each NFT, we thought we would give the
            community 7 days to value each title without distractions from the
            artwork and other metadata.
          </Question>
          <Question number='4' question="Why are you releasing 10 batches of 1,000 NFTs instead of 1 batch of 10,000 NFTs?" open={open4} setOpen={setOpen4}>
            In short; we were interested in giving time for the community to
            participate in the polling process; from identifying changes which
            could best serve the Tezos ecosystem, and formulating them into polls
            for Tezos Foundation to digest the result. Running 10 Barn Openings
            (minting) and linking them to 10 different polling questions
            thoughtout 2022, provides us the opportunity to build a community to
            achieve these goals.
          </Question>
          <Question number='5' question="How can I propose a poll question for a future Barn Opening (minting)?" open={open5} setOpen={setOpen5}>
            Just jump onto any of our socials and drop your question there. We
            will collate and select the change request we believe would be
            easiest to implement with the highest impact.
          </Question>
          <Question number='6' question="Are you having a crack at Tezos Foundation... are you a Tezos hater?" open={open6} setOpen={setOpen6}>
            No, not at all; on either point. We have been invested and involved
            with Tezos since the original ICO and we are positive Tezos can
            achieve all it set out to do. We have a few ideas we think are worth
            raising and rather than progressing them through the usual social
            channels, we thought we would do something fun. Added to that, we
            think art can be more than just a few pixels in the cloud. Tezos
            Farmation is conceptual art.
          </Question>
          <Question number='7' question="What will you do if the Tezos Foundation implement one of the Barn Opening Poll Questions?" open={open7} setOpen={setOpen7}>
            Have a quiet drink with a warm glow inside.
          </Question>
          <Question number='8' question="If I miss out on a Barn Opening (minting) will I have another opportunity to purchase the released NFTS?" open={open8} setOpen={setOpen8}>
            Once each Barn Opening (minting) is complete you will be able to
            purchase through the secondary market over at <a href="https://objkt.com/" target="_blank" rel="noreferrer">OBJKT</a>
          </Question>
          <Question number='9' question="How do you prevent gamification through the Barn Opening (minting) process?" open={open9} setOpen={setOpen9}>
            We have included a Provenance Hash in the smart contract, which
            points to an IPFS address of a file containing hashes for all
            artwork images and their IDs. If we change a single artifact in the
            Provenance file, the hash will be different. This will enable anyone
            to calculate the hash from the raw artifacts to verify that the
            resultant hash does indeed equal our Provenance Hash; therefore
            proving no alterations have been made. Additionally, we implemented
            a random start for the sale. This ensures that we cannot put all the
            rare NFTs in the beginning of the collection and mint them
            ourselves; which is also observable in the smart contract.
          </Question>
          <Question number='10' question="What are the future benefits of the NFTs and roadmap for the project?" open={open10} setOpen={setOpen10}>
            Tezos Farmation NFTs are just art. Hopefully you like them, buy them,
            trade them, and appreciate them for all they can be. There are no
            extra benefits associated with the NFTs.
          </Question>
          <Question number='11' question="Do you really believe 'All Animals Are Equal'?" open={open11} setOpen={setOpen11}>
            If by 'Animals' you are referring to people; then we believe that
            we are the closest we've ever been to having equal access to all life
            can deliver. It's still a moon shot, but fortunately the crypto
            community is doing more than its fair share of fueling the rocket.
          </Question>
        </div>
      </div>
    </Container>
  )
}

export default HomeFaq
