import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


const HomeMotto = ({...props}) => {
  return (
    <Container className="px-3 px-md-5 pb-3 pt-5 text-left">
      <Row className="px-3">
        <Col className="p-0 mt-3">
          <a className="pointer" href="/animal-farm">
            <div className="helvetica-heading h1 pb-3">EXPLORE RARITIES & READ GEORGE ORWELL'S ANIMAL FARM</div>
            <img src="/static/anonymous/img/motto.png" className="img-fluid rounded" alt="Motto" />
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default HomeMotto
