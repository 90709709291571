import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

const chartOptions = {
  plugins: {
    legend: {
      reverse: true,
      labels: {
        font: {
          family: 'HelveticaHeading',
          size: 20,
        },
      },
    },
  },
}

const HomePolls = ({noQty, yesQty}) => {

  const chartData = {
    labels: ['No', 'Yes'],
    datasets: [
      {
        label: 'Total Votes',
        data: [noQty, yesQty],
        backgroundColor: [
          'rgba(252, 107, 166, 1)',
          'rgba(76, 130, 59, 1',
        ],
        borderColor: [
          'rgba(0, 0, 0, 1)',
          'rgba(0, 0, 0, 1)',
        ],
        borderWidth: 3,
      },
    ],
  }

  return (
    <Container className="px-3 px-md-5 py-0">
      <Row className="helvetica-heading h1 mt-5 px-3 text-left">THE POLLS</Row>
      <Row className="my-3 px-3">
        <div className="helvetica-body px-0">
          Art and politics have never been mutally exclusive; sometimes
          working with a political agenda, sometimes working against a
          political agenda, sometimes becoming a political agenda of their
          very own. Tezos Farmation is interested in suggesting changes
          which could be implemented by Tezos Foundation, so with every
          Barn Opening (minting) we will be conducting a poll on an action
          that could benefit the Tezos ecosystem.
          <br />
          <br />
          We think generative NFT profile pictures are a fun way to express a
          collective point of view.
        </div>
      </Row>
      <Row className="align-items-center">
        <Col xs={12} md={6} lg={8} className="pb-3">
          <div className="helvetica-heading h4 mt-2 p-0">1ST BARN OPENING POLL</div>
          <div className="px-0">
            <div className="helvetica-heading blockquote pt-2">
              "Should Tezos Foundation begin a planned reduction of the amount of XTZ they are currently staking?"
            </div>
            <div className="helvetica-body h5">
              Total Votes:
              {" "}{(noQty === null || yesQty === null) ? ("Querying Contract") : (yesQty + noQty)}
            </div>
          </div>
        </Col>
        <Col className="px-lg-5" xs={12} md={6} lg={4}>
          <Doughnut data={chartData} options={chartOptions} />
        </Col>
      </Row>
    </Container>
  )
}

export default HomePolls
