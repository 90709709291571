import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


const HomeHero = ({...props}) => {
  return (
    <div className="home-hero">
      <Container className="home-logo-hero text-white">
        <Row>
          <img src="/static/anonymous/img/logo_hero.png" alt="Hero Logo" />
        </Row>
        <Row className="helvetica-heading h2 pt-5 w-75 m-auto text-left">
          <Col className="m-0 p-0">
            ALL ANIMALS ARE EQUAL
          </Col>
        </Row>
        <Row className="helvetica-body pt-3 w-75 m-auto text-left">
          <Col className="m-0 p-0">
            Tezos Farmation is conceptual art; is challenging how we share our
            views; is a public poll; is a collection of 10,000 unique works of
            art based on George Orwell's political satire,{" "}<a href="/animal-farm">Animal Farm</a>; and lives
            on the Tezos blockchain.
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HomeHero
