import React from 'react'
import {useMatch, useResolvedPath} from "react-router-dom"

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBook, faHome} from '@fortawesome/free-solid-svg-icons'
import {faDiscord, faInstagram, faTelegramPlane, faTwitter} from '@fortawesome/free-brands-svg-icons'


const NavIcon = ({icon, link, name, padding, tooltip}) => {
  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip id={`id-tooltip-${name}`}>{tooltip}</Tooltip>}>
      <a className="pointer" href={link}>
        <div className="header-button" style={padding}>
          <FontAwesomeIcon icon={icon}/>
        </div>
      </a>
    </OverlayTrigger>
  )
}

const Social = ({icon, link, padding, social}) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip id={`id-tooltip-${social}`}>Join our {social}</Tooltip>}>
    <a className="mr-1 mr-sm-2 pointer" href={link} target="_blank" rel="noreferrer">
      <div className="header-button" style={padding}>
        <FontAwesomeIcon icon={icon}/>
      </div>
    </a>
  </OverlayTrigger>
)

const Header = ({...props}) => {
  let resolved = useResolvedPath('/')
  let match = useMatch({path: resolved.pathname, end: true})
  return (
    <Navbar className="header p-1 pt-2 px-3" expand="lg" fixed="top">
      <Container>
        <div className="header-links d-flex">
          {match ?
            <NavIcon icon={faBook} link="/animal-farm" name="novel" padding={{padding: `5px 2px 0px 0px`}} tooltip="Explore NFT Rarities & Read George Orwell's Animal Farm" />
          :
            <NavIcon icon={faHome} link="/" name="home" padding={{padding: `3px 2px 0px 2px`}} tooltip="Head back home" />
          }
        </div>
        <div className="header-links d-flex ml-auto">
          <Social icon={faTelegramPlane} link="https://t.me/TezosFarmation" padding={{padding: `4px 5px 0px 0px`}} social="Telegram" />
          <Social icon={faDiscord} link="https://discord.gg/qxY3kDBNRQ" padding={{padding: `4px 0px 0px 0px`}} social="Discord" />
          <Social icon={faTwitter} link="https://twitter.com/TezosFarmation" padding={{padding: `4px 0px 0px 1px`}} social="Twitter" />
          <Social icon={faInstagram} link="https://www.instagram.com/animalis__aequalis/" padding={{padding: `4px 0px 0px 1px`}} social="Instagram" />
          <a className="header-mint helvetica-heading" href="/#id-mint">MINT</a>
        </div>
      </Container>
    </Navbar>
  )
}

export default Header
