import React, {useCallback, useEffect, useState} from 'react'

import {TezosToolkit} from '@taquito/taquito'
import {Tzip16Module} from "@taquito/tzip16";
import {BeaconWallet} from '@taquito/beacon-wallet'

import Footer from '../container/footer/Footer'
import Header from '../container/header/Header'
import HomeActions from './HomeActions'
import HomeArt from './HomeArt'
import HomeFaq from './HomeFaq'
import HomeGoals from './HomeGoals'
import HomeHero from './HomeHero'
import HomeMotto from './HomeMotto'
import HomePolls from './HomePolls'
import HomeProvenance from './HomeProvenance'
import HomeTeam from './HomeTeam'
import HomeUniqueness from './HomeUniqueness'


const walletOptions = {
  name: 'Tezos Farmation',
  iconUrl: 'https://tezosfarmation.com/static/anonymous/img/favicon-32x32.png',
  preferredNetwork: "mainnet",
}

const Tezos = new TezosToolkit('https://mainnet.api.tez.ie')
const wallet = new BeaconWallet(walletOptions)
Tezos.addExtension(new Tzip16Module());
Tezos.setWalletProvider(wallet)
const contractAddress = "KT1RPjHmjNFjWQfWhGq7ee48ezGmEvECNRwP"

function Home() {

  const [yesQty, setYesQty] = useState(null)
  const [noQty, setNoQty] = useState(null)

  const getVoteQty = useCallback(() => {
    Tezos.contract
      .at(contractAddress)
      .then((contract) => (contract.storage()))
      .then((storage) => {
        setYesQty(storage['votes'].get('yes').toNumber())
        setNoQty(storage['votes'].get('no').toNumber())
      })
      .catch(e => {
        setYesQty(0)
        setNoQty(0)
      })
  }, [])

  useEffect(() => {
    getVoteQty()
  }, [getVoteQty])

  return (
    <div className="tezosfarmation">
      <div className="page">
        <Header />
        <HomeHero />
        <div className="bg-white w-100">
          <HomeArt />
          <HomeMotto />
          <HomePolls noQty={noQty} yesQty={yesQty} />
          <HomeUniqueness />
          <HomeGoals />
          <HomeActions Tezos={Tezos} contractAddress={contractAddress} getVoteQty={getVoteQty} wallet={wallet} />
          <HomeFaq />
          <HomeTeam />
          <HomeProvenance contractAddress={contractAddress} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home
