import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'


const EmailCol = ({role, email}) => (
  <Col lg={4}>
    <a className="px-0" href={`mailto: ` + email}>
      <b>{role}:</b> {email}
    </a>
  </Col>
)


const Footer = ({...props}) => {
  return (
    <footer className="footer my-0 py-0" id="id-footer">
      <Container className="my-0 px-3 px-md-5 py-3">
        <Row className="helvetica-heading h6 mb-1 mt-0">
          <Col>
            <div className="px-0 w-100">
              <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
              Private Enquiries
            </div>
          </Col>
        </Row>
        <Row className="py-0">
          <EmailCol role="Collaboration" email="mrs.jones@tezosfarmation.com" />
          <EmailCol role="Artwork" email="mr.jones@tezosfarmation.com" />
          <EmailCol role="Smart Contracts" email="mr.pilkington@tezosfarmation.com" />
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
