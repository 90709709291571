import React, {useCallback, useEffect, useState} from 'react'

import {tzip16} from "@taquito/tzip16";

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTag, faThumbsUp} from '@fortawesome/free-solid-svg-icons'

const totalMintQty = 1000
const nftCost = 15
const nftCostDiscounted = 10


const Heading = ({title}) => (
  <Row className="mt-3">
    <Col md={12}>
      <div className="helvetica-heading h4 mt-3 px-0">{title}</div>
    </Col>
  </Row>
)

const MintQtyButton = ({qty, purchaseQty, setPurchaseQty}) => (
  <Col className="mx-1 my-0 p-0">
    <div className="home-mint-qty" onClick={() => setPurchaseQty(qty)}>
      <img src="/static/anonymous/img/preview1.png" className={qty <= purchaseQty ? "img-fluid rounded" : "img-fluid rounded opacity-25"} alt="Reveal" />
      <div className="home-mint-qty-text helvetica-heading h3">
        {qty === purchaseQty ? qty : ""}
      </div>
    </div>
  </Col>
)

const HomeActions = ({Tezos, contractAddress, getVoteQty, wallet}) => {

  const notConnected = '#NotConnected'
  const [mintQty, setMintQty] = useState(0)
  const [promiseStatus, setPromiseStatus] = useState(null)
  const [userAddress, setUserAddress] = useState(notConnected)
  const [isSaleOpen, setIsSaleOpen] = useState('false')
  const [isSoldOut, setIsSoldOut] = useState('false')
  const [isVoted, setIsVoted] = useState('false')
  const [isMinted, setIsMinted] = useState(0)
  const [purchaseQty, setPurchaseQty] = useState(1)

  const getIsSaleOpen = useCallback(() => {
    Tezos.contract
      .at(contractAddress)
      .then((contract) => (contract.storage()))
      .then((storage) => (setIsSaleOpen(storage['sale_started'].toString())))
      .catch(e => (setIsSaleOpen('false')))
  }, [Tezos.contract, contractAddress])

  const getMintQty = useCallback(() => {
    Tezos.contract
      .at(contractAddress)
      .then((contract) => (contract.storage()))
      .then((storage) => {
        var mintedQty = storage['n_minted'].toNumber()
        var maxSupplyQty = storage['max_supply'].toNumber()
        setMintQty(mintedQty)
        setIsSoldOut((mintedQty >= maxSupplyQty).toString())
      })
      .catch(e => (setMintQty(0)))
  }, [Tezos.contract, contractAddress])

  const getIsVoted = useCallback((address) =>
    Tezos.contract.at(contractAddress, tzip16)
      .then(contract => (contract.tzip16().metadataViews()))
      .then(views => (views.getVoted().executeView(address)))
      .then(result => {
        setIsVoted(result.toString())
        setPromiseStatus(null)
      })
      .catch(error => {
        setIsVoted('false')
        setPromiseStatus(null)
      }), [Tezos.contract, contractAddress]
  )

  const connect = useCallback(() => {
    setPromiseStatus('Connecting')
    wallet
      .requestPermissions({network: {type: 'mainnet'}})
      .then((_) => wallet.getPKH())
      .then((address) => {
        setUserAddress(address.toString())
        setPromiseStatus('Checking')
        getIsVoted(address.toString())
      })
  }, [wallet, getIsVoted])

  const vote = useCallback((chosenOption) => {
    setPromiseStatus('Voting')
    Tezos.wallet
      .at(contractAddress)
      .then((contract) => (contract.methods.vote(chosenOption).send()))
      .then((op) => {
        op.confirmationObservable(1).subscribe(
          event => {
            getIsVoted(userAddress)
            .then(getVoteQty)
          }
        )
      })
  }, [Tezos.wallet, contractAddress, getIsVoted, getVoteQty, userAddress])

  const mint = useCallback(() => {
    setPromiseStatus('Minting')
    Tezos.wallet
      .at(contractAddress)
      .then((contract) => {
        var mintAmount = isVoted === 'true' ? nftCostDiscounted : nftCost
        return contract.methods.mint(purchaseQty).send({amount: (mintAmount*purchaseQty)})
      })
      .then((op) => {
        op.confirmationObservable(1).subscribe(
          event => {
            setPromiseStatus(null)
            setIsMinted(1)
            getMintQty()
          }
        )
      })
  }, [Tezos.wallet, contractAddress, getMintQty, isVoted, purchaseQty])

  useEffect(() => {
    getMintQty()
    getIsSaleOpen()
  }, [getMintQty, getIsSaleOpen])

  return (
    <Container className="px-3 px-md-5 py-5">
      <Row className="helvetica-heading h1 mt-4 px-3 text-left" id="id-mint">THE ACTIONS</Row>
      <Row className="mt-3 px-3">
        <div className="helvetica-body px-0">
          Welcome to the business end of Tezos Farmation. Follow through the
          workflow in this section to participate in both the current Tezos
          Farmation Poll and Barn Opening. You will need a Tezos wallet
          extension within your browser (we suggest{' '}
          <a className="pointer" href="https://templewallet.com/" target="_blank" rel="noreferrer">
            Temple
          </a>
          ), and some XTZ sent to a Tezos address in your browser's wallet.
        </div>
      </Row>
      <Heading title="STEP 1. CONNECT" />
      {(userAddress === notConnected && promiseStatus !== "Connecting") ?
        <Row className="align-items-center mt-3 pb-3">
          <Col md={8} className="pb-2 pb-md-0">
            <div className="helvetica-body">
              Connect Tezos Farmation to your Tezos Wallet
            </div>
          </Col>
          <Col md={4}>
            <div className="helvetica-body px-0">
              <div className="home-connect-button helvetica-heading" onClick={() => connect()}>
                CONNECT YOUR WALLET
              </div>
            </div>
          </Col>
        </Row>
      :
        <Row className="mt-3 pb-3">
          <Col>
            {promiseStatus === "Connecting" ?
              <div className="helvetica-body">
                <i><b>We are currently attempting to connect to your Tezos wallet... it may take a moment.</b></i>
              </div>
            :
              <div className="helvetica-body">
                <FontAwesomeIcon className="home-thumbs-up mr-3" icon={faThumbsUp} />
                You have successfully connected your wallet (Address:{' '}
                {userAddress}) to Tezos Farmation.
              </div>
            }
          </Col>
        </Row>
      }
      <Heading title="STEP 2. VOTE (OPTIONAL)" />
      {userAddress === notConnected ?
        <Row className="mt-3 pb-3">
          <Col>
            <div className="helvetica-body">
              Once your wallet is connected you will be able to vote in the
              current Tezos Farmation Poll.
            </div>
          </Col>
        </Row>
      :
        <Row className="align-items-center mt-3 pb-3">
          {(isVoted === 'false' && promiseStatus !== 'Checking' && promiseStatus !== 'Voting') ?
            <>
              <Col md={8}>
                <div className="helvetica-body">
                  Vote in our current Barn Opening Poll
                </div>
                <div className="helvetica-heading blockquote m-0 pt-2 pb-0">
                  "Should Tezos Foundation begin a planned reduction of the amount of XTZ they are currently staking?"
                </div>
              </Col>
              <Col className="d-flex" md={4}>
                <div className="d-flex mx-0 my-auto p-0 w-100">
                  <div className="home-vote-yes helvetica-heading" style={{margin: `5px 1% 0px 0px`}} onClick={() => vote('yes')}>
                    YES
                  </div>
                  <div className="home-vote-no helvetica-heading" style={{margin: `5px 0px 0px 1%`}} onClick={() => vote('no')}>
                    NO
                  </div>
                </div>
              </Col>
            </>
          :
            <Col>
              {(promiseStatus === "Checking" || promiseStatus === "Voting") ?
                <div className="helvetica-body">
                  {promiseStatus === "Checking" ?
                    <i><b>We are in the process of checking to see if you have already voted... it may take a moment.</b></i>
                  :
                    <i><b>We are in the process of committing your vote to the Tezos blockchain... it may take a moment.</b></i>
                  }
                </div>
              :
                <div className="helvetica-body">
                  <FontAwesomeIcon className="home-thumbs-up mr-3" icon={faThumbsUp} />
                  Your vote has been recieved and counted, thank you for participating.
                </div>
              }
            </Col>
          }
        </Row>
      }
      <Heading title="STEP 3. MINT" />
      {(isSaleOpen === 'false' || isSoldOut === 'true') ?
        <Row className="mt-3">
          <Col>
            {isSaleOpen === 'false' ?
              <div className="helvetica-body">
                Our first Barn Opening (minting) will begin at 14:00 GMT, on the
                Ides of March 2022 (15th March).
              </div>
            :
              <div className="helvetica-body">
                Sold Out
              </div>
            }
          </Col>
        </Row>
      :
        <>
          {userAddress === notConnected ?
            <Row className="mt-3">
              <Col>
                <div className="helvetica-body">
                  Once your wallet is connected and if the Barn is Open you will be
                  able to mint the current Tezos Farmation NFT.
                </div>
              </Col>
            </Row>
          :
            <Row className="align-items-center mt-3">
              {promiseStatus === "Minting" ?
                <Col>
                  <div className="helvetica-body">
                    <i><b>We are in the process of minting your NFT on the Tezos blockchain... it may take a moment.</b></i>
                  </div>
                </Col>
              :
                <>
                  <Col md={8}>
                    <div className="helvetica-body pb-2 pb-md-0">
                      {isMinted === 0 ? "Mint your " : "Thanks for minting. Mint another "}Tezos Farmation NFT for{" "}<b>{isVoted === 'false' ? nftCost : nftCostDiscounted}{" "}XTZ</b> in our #1 Barn Opening.
                      {(isVoted === 'false') ?
                        <>
                          <br />
                          <FontAwesomeIcon className="home-discount mr-2" icon={faTag} />
                          <b>33.3% discount</b> if you vote in Step 2.
                        </>
                      :
                        <>
                          {''}
                        </>
                      }
                      <br />
                      The artwork will be revealed 7 days after the Barn Opening.
                      <br />
                      However, the NFT Title (words from George Orwell's Animal Farm) will be revealed upon purchase.
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="helvetica-body px-0">
                      <div className="w-100 text-center mt-1">
                        <Row className="m-0 p-0">
                          <MintQtyButton qty={1} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty}/>
                          <MintQtyButton qty={2} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty} />
                          <MintQtyButton qty={3} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty} />
                          <MintQtyButton qty={4} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty} />
                          <MintQtyButton qty={5} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty} />
                        </Row>
                        <Row className="mx-0 my-2 p-0">
                          <MintQtyButton qty={6} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty} />
                          <MintQtyButton qty={7} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty} />
                          <MintQtyButton qty={8} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty} />
                          <MintQtyButton qty={9} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty} />
                          <MintQtyButton qty={10} purchaseQty={purchaseQty} setPurchaseQty={setPurchaseQty} />
                        </Row>
                      </div>
                      <div className="home-connect-button helvetica-heading" onClick={() => mint()}>
                        MINT{' '}{purchaseQty}{' '}NFT{purchaseQty > 1 ? 'S' : ''} FOR{' '}{(purchaseQty * (isVoted === 'false' ? nftCost : nftCostDiscounted)).toLocaleString()}{' '}XTZ
                      </div>
                    </div>
                  </Col>
                </>
              }
            </Row>
          }
        </>
      }
      <Heading title="MINTING PROGRESS" />
      <Row className="mt-3 pb-3">
        <Col>
          <div className="helvetica-body">
            There has been{" "}{mintQty}{" "}Tezos Farmation NFTs minted, with{" "}{(totalMintQty - mintQty).toLocaleString()}{" "}remaining in this Barn Opening.
          </div>
        </Col>
      </Row>
      <Row className="mt-3 pb-3">
        <Col>
          <div className="home-mint-chart">
            <div className="home-mint-chart-progress" style={{width: (String(mintQty/totalMintQty*100) + "%")}}>{" "}</div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default HomeActions
