import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


const HomeUniqueness = ({...props}) => {
  return (
    <Container className="px-3 px-md-5 pb-0 pt-3">
      <Row className="helvetica-heading h1 mt-5 px-3 text-left">THE UNIQUENESS</Row>
      <Row className="mt-3">
        <Col className="pb-5 pb-md-0" md={4}>
          <img src="/static/anonymous/img/preview1.png" className="img-fluid rounded" alt="Reveal 1" />
          <div className="helvetica-heading h4 mt-3">RELEASES</div>
          <div className="helvetica-body mt-2">
            Throughout 2022 Tezos Farmation will open the barn door on ten
            occasions to allow anyone with an interest in our project to
            mint an NFT. Each barn opening (minting) will focus on one
            animal.
          </div>
          <div className="helvetica-body mt-2">
            If you miss out on a barn opening event you will still have an
            opportunity to purchase the released NFTs through the secondary
            market over at <a href="https://objkt.com/" target="_blank" rel="noreferrer">OBJKT</a>.
          </div>
        </Col>
        <Col className="pb-5 pb-md-0" md={4}>
          <img src="/static/anonymous/img/preview2.png" className="img-fluid rounded" alt="Reveal 2" />
          <div className="helvetica-heading h4 mt-3">POLL POSITION</div>
          <div className="helvetica-body mt-2">
            Community discussion and feedback helps build a strong
            ecosystem and should be rewarded, so Tezos Farmation is providing
            a 1/3 discount on the minting of our NFTs if the purchasing address
            has participated in the poll associated with each release.
          </div>
          <div className="helvetica-body mt-2">
            Please see our section above regarding the polls, and you will be
            prompted to vote when you connect your wallet; noting that
            the discount is offered to both for and against votes.
            Participation is what counts.
          </div>
        </Col>
        <Col md={4}>
          <img src="/static/anonymous/img/preview3.png" className="img-fluid rounded" alt="Reveal 3" />
          <div className="helvetica-heading h4 mt-3">NOVEL SLICE</div>
          <div className="helvetica-body mt-2">
            To further link our animals together we thought it would be fun
            to attach unique, but ordered, titles to the metadata. To
            achieve this we decided to stand on the shoulders of a giant,
            George Orwell himself, and use the complete{" "}<a href="/animal-farm">Animal Farm</a> text.
          </div>
          <div className="helvetica-body mt-2">
            I.e. if we start at Chapter 1<br/>
            NFT #00001 will have the title <b><i>"Mr. Jones, of"</i></b>,<br/>
            NFT #00002 will be <b><i>"the Manor Farm,"</i></b>,<br/>
            NFT #00003 will be <b><i>"had locked the"</i></b>,<br/>
            so on and so forth.
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default HomeUniqueness
