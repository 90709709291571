import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


const HomeGoals = ({...props}) => {
  return (
    <Container className="px-3 px-md-5 pt-3">
      <Row className="helvetica-heading h1 mt-5 px-3 text-left">THE GOALS</Row>
      <Row className="mt-3 px-3">
        <div className="helvetica-heading h4 mt-3 px-0">STRETCH GOAL</div>
        <div className="helvetica-body mt-2 px-0">
          Our lofty stretch goal is for Tezos Farmation to act as a visually
          pleasing, thought provoking, abstracted moral compass, helping all
          those involved with Tezos to remember that we are all equal.
        </div>
        <div className="helvetica-heading h4 mt-3 px-0">MOON SHOT</div>
        <div className="helvetica-body mt-2 px-0">
          Come with us on our journey, building our community, and you never
          know, together we might be able to transcend our ambitions within
          the Tezos ecosystem and become a wider symbol of equality and
          transparency for the crypto community at large.
        </div>
      </Row>
    </Container>
  )
}

export default HomeGoals
