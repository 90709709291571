import React, {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Row from 'react-bootstrap/Row'
import Tooltip from 'react-bootstrap/Tooltip'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDoubleLeft, faAngleDoubleRight, faEllipsisH, faFilter} from '@fortawesome/free-solid-svg-icons'

import Header from '../container/header/Header'

import {filterDefaults, allChapters, allAnimals, allBackgrounds, allBodies, allClothes, allExpressions, allEyewears, allHeadwears, allRankGroups} from '../contexts/Layers'
import {novelData} from '../contexts/Novel'


const ipfs = 'https://tezosfarmation.mypinata.cloud/ipfs/'
const objktUrl = 'https://objkt.com/asset/tezos_farmation/'
const LineBreak = ({isLineBreak}) => (
  isLineBreak === true ? <div className="float-left w-100">{" "}</div> : ""
)


function randomPreviewUrl() {
  var max = 3
  var number = Math.floor(Math.random() * max) + 1
  var url = '/static/anonymous/img/preview' + number.toString() + '.png'
  return url
}


function AnimalFarm() {

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [itemsPerPage] = useState(100)
  const [novelFiltered, setNovelFiltered] = useState(novelData)
  const [filters, setFilters] = useState(filterDefaults)
  const [filterChapter, setFilterChapter] = useState(allChapters)
  const [filterAnimal, setFilterAnimal] = useState(allAnimals)
  const [filterBackground, setFilterBackground] = useState(allBackgrounds)
  const [filterBody, setFilterBody] = useState(allBodies)
  const [filterClothes, setFilterClothes] = useState(allClothes)
  const [filterExpression, setFilterExpression] = useState(allExpressions)
  const [filterEyewear, setFilterEyewear] = useState(allEyewears)
  const [filterHeadwear, setFilterHeadwear] = useState(allHeadwears)
  const [filterRankGroup, setFilterRankGroup] = useState(allRankGroups)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(novelFiltered.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(novelFiltered.length / itemsPerPage))
    window.scrollTo({top: 0});
  }, [itemOffset, itemsPerPage, novelFiltered])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % novelData.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    )
    setItemOffset(newOffset)
  }

  function resetPageCounts() {
    setCurrentItems(null)
    setPageCount(0)
    setItemOffset(0)
  }

  function handleChange(layer, event) {
    var selection = event.target.value
    var newFilters = {...filters, [layer]: selection}
    var newNovelData = novelData.filter(nft => (
      (newFilters.chapter === 'All' || nft.chapter === newFilters.chapter)
      && (newFilters.animal === 'All' || nft.animal === newFilters.animal)
      && (newFilters.background === 'All' || nft.background === newFilters.background)
      && (newFilters.body === 'All' || nft.body === newFilters.body)
      && (newFilters.clothes === 'All' || nft.clothes === newFilters.clothes)
      && (newFilters.expression === 'All' || nft.expression === newFilters.expression)
      && (newFilters.eyewear === 'All' || nft.eyewear === newFilters.eyewear)
      && (newFilters.headwear === 'All' || nft.headwear === newFilters.headwear)
      && (newFilters.rankGroup === 'All' || Number(nft.rankGroup) === Number(newFilters.rankGroup))
    ))
    resetPageCounts()
    setFilterChapter(updateFilter(newNovelData, 'chapter', allChapters))
    setFilterAnimal(updateFilter(newNovelData, 'animal', allAnimals))
    setFilterBackground(updateFilter(newNovelData, 'background', allBackgrounds))
    setFilterBody(updateFilter(newNovelData, 'body', allBodies))
    setFilterClothes(updateFilter(newNovelData, 'clothes', allClothes))
    setFilterExpression(updateFilter(newNovelData, 'expression', allExpressions))
    setFilterEyewear(updateFilter(newNovelData, 'eyewear', allEyewears))
    setFilterHeadwear(updateFilter(newNovelData, 'headwear', allHeadwears))
    setFilterRankGroup(updateFilter(newNovelData, 'rankGroup', allRankGroups))
    setNovelFiltered(newNovelData)
    setFilters(newFilters)
  }

  function updateFilter(newNovelData, filterName, allOptions) {
    var newOptions = [...allOptions]
    for (var i = allOptions.length -1; i > 0; i--) {
      var qty = newNovelData.filter(nft => nft[filterName] === allOptions[i]['id']).length
      if (qty === 0) {
        newOptions.splice(i, 1)
      }
    }
    return newOptions
  }

  function resetFilters() {
    if(JSON.stringify(filters) !== JSON.stringify(filterDefaults)){
      resetPageCounts()
      setNovelFiltered(novelData)
      setFilters(filterDefaults)
      setFilterChapter(allChapters)
      setFilterAnimal(allAnimals)
      setFilterBackground(allBackgrounds)
      setFilterBody(allBodies)
      setFilterClothes(allClothes)
      setFilterExpression(allExpressions)
      setFilterEyewear(allEyewears)
      setFilterHeadwear(allHeadwears)
      setFilterRankGroup(allRankGroups)
    }
  }

  const CustomToggle = ({children, eventKey}) => {
    const decoratedOnClick = useAccordionButton(eventKey)
    return (
      <div className="pointer" onClick={decoratedOnClick}>{children}</div>
    )
  }

  const FilterOptions = ({filter, name, filterOptions}) => {
    return (
      <Col xs={6} sm={3} className="px-2 py-1">
        <Form.Label className="text-capitalize">
          {name ? name : filter}
        </Form.Label>
        <Form.Select aria-label={filter} size="sm" onChange={(e) => handleChange(filter, e)} value={filters[filter]}>
          {filterOptions.map(o => <option value={o.id} key={o.id}>{o.label}</option>)}
        </Form.Select>
      </Col>
    )
  }

  return (
    <div id="id-tezosfarmation" className="tezosfarmation">
      <div className="page mb-0 pb-5">
        <Header />
        <div className="novel-background">{" "}</div>
        <div className="novel-holder w-100 pb-5">
          <Container className="px-3 px-md-5 pb-5 pt-0">
            <Row className="mt-5">
              <Col className="m-0 p-0 mt-5">
                <Accordion defaultActiveKey="0">
                  <Card className="bg-transparent border-0 m-0">
                    <Card.Header className="bg-transparent border-0 px-2">
                      <CustomToggle eventKey="1">
                        <div className="d-flex">
                          <OverlayTrigger placement="bottom" overlay={<Tooltip id={`id-tooltip-rarity-filter`}>Toggle Rarity Filters</Tooltip>}>
                            <div className="novel-filters-button">
                              <FontAwesomeIcon icon={faFilter}/>
                            </div>
                          </OverlayTrigger>
                          <div className="novel-filters-count">
                            Displaying items {itemOffset + 1} to {currentItems && (itemOffset + currentItems.length)} of {novelFiltered.length.toLocaleString(undefined, {maximumFractionDigits: 2})}
                          </div>
                        </div>
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="p-0 pb-3">
                        <Form className="p-0" autoComplete="off">
                          <Container className="p-0">
                            <Row className="m-0">
                              <FilterOptions filter="chapter" filterOptions={filterChapter} />
                              <FilterOptions filter="animal" filterOptions={filterAnimal} />
                              <FilterOptions filter="background" filterOptions={filterBackground} />
                              <FilterOptions filter="body" filterOptions={filterBody} />
                              <FilterOptions filter="clothes" filterOptions={filterClothes} />
                              <FilterOptions filter="expression" filterOptions={filterExpression} />
                              <FilterOptions filter="eyewear" filterOptions={filterEyewear} />
                              <FilterOptions filter="headwear" filterOptions={filterHeadwear} />
                              <FilterOptions filter="rankGroup" name= "Rank Group" filterOptions={filterRankGroup} />
                              <Col xs={12} className="px-2 pb-1 pt-3">
                                <div className="novel-filters-reset helvetica-heading" onClick={() => resetFilters()}>Reset Filters</div>
                              </Col>
                            </Row>
                          </Container>
                        </Form>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
            <Row>
              <Col className="m-0 p-0 mt-3">
                {currentItems && currentItems.map(
                  titleData =>
                  <>
                    <LineBreak isLineBreak={titleData.isChapter} />
                    {titleData.mintId <= 3000 ?
                      <div key={titleData.orderId} className="novel-data float-left px-2 py-2 text-center">
                        <a href={objktUrl + titleData.mintId} target="_blank" rel="noreferrer">
                          <div className="position-relative">
                            <img src={ipfs + titleData.ipfsKey + '/' + titleData.mintId + ".png"} className="img-fluid rounded" alt={"Animal" + titleData.orderId} />
                            <div className="novel-rank">
                              {titleData.rank}
                            </div>
                          </div>
                          <div className={(titleData.isHeading ? "novel-heading helvetica-heading" : "helvetica-body") + " novel-text  mt-2"}>
                            {titleData.title}
                          </div>
                        </a>
                      </div>
                    :
                      <div key={titleData.orderId} className="novel-data float-left px-2 py-2 text-center">
                        <div>
                          <img src={randomPreviewUrl()} className="img-fluid rounded" alt={"Animal" + titleData.orderId} />
                        </div>
                        <div className={(titleData.isHeading ? "novel-heading helvetica-heading" : "helvetica-body") + " novel-text  mt-2"}>
                          {titleData.title}
                        </div>
                      </div>
                    }
                    <LineBreak isLineBreak={titleData.isChapter} />
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <footer className="footer-paginate m-0 p-0" id="id-footer">
        <Container className="my-0 px-3 px-md-5 py-3">
          <Row className="helvetica-heading mb-1 mt-0">
            <Col className="d-flex">
              <ReactPaginate
                className="d-flex mx-auto"
                previousLinkClassName="footer-paginate-btn mr-2"
                pageLinkClassName="footer-paginate-btn mr-2"
                nextLinkClassName="footer-paginate-btn"
                breakLinkClassName="footer-paginate-break mr-2"
                activeLinkClassName="footer-paginate-active"
                disabledLinkClassName="footer-paginate-disable"
                breakLabel={<FontAwesomeIcon icon={faEllipsisH} />}
                nextLabel={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                renderOnZeroPageCount={null}
              />
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default AnimalFarm
