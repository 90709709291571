import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


const HomeArt = ({...props}) => {
  return (
    <Container className="px-3 px-md-5 pb-3 pt-5 text-left">
      <Row className="px-3">
        <Col className="v-100 pl-0 pr-0 pr-lg-5" lg={6}>
          <div className="helvetica-heading h1">THE ART</div>
          <div className="mt-auto helvetica-body">
            Tezos Farmation is releasing the descendants of George Orwell's
            {" "}<a href="/animal-farm">Animal Farm</a>, four score and plenty of generations further down
            the road, onto the Tezos blockchain as Non Fungible Tokens or
            NFTs.
          </div>
          <div className="helvetica-heading h4 pt-2">ANIMAL FARM NEXT GEN</div>
          <div className="mt-auto helvetica-body">
            This mixed up bunch of animals have descended from farms
            all over the world, and since that very first attempt of self
            governance on Manor Farm, they have tried and tested so many
            forms of government. Some have been better recieved than others.
            None have been without their flaws. Now, there's no easy way to
            pick their political alignments, their outlooks and attire have
            been exposed to too many fads and trends.
          </div>
          <div className="helvetica-heading h4 pt-2">RARITIES</div>
          <div className="mt-auto pb-5 pb-lg-0 helvetica-body">
            Our Tezos Farmation participants were kind enough to volunteer
            information about themselves, stored as meta data to their NFT.
            However, when it came to gender, we didn't feel it was
            appropriate for us to ask, much less guess, their social or
            biological gender identification.
          </div>
        </Col>
        <Col lg={6}>
          <Row>
            <Col className="p-1"><img src="/static/anonymous/img/pig1.jpeg" className="img-fluid rounded" alt="Pig 1" /></Col>
            <Col className="p-1"><img src="/static/anonymous/img/pig2.jpeg" className="img-fluid rounded" alt="Pig 2" /></Col>
            <Col className="p-1"><img src="/static/anonymous/img/pig3.jpeg" className="img-fluid rounded" alt="Pig 3" /></Col>
          </Row>
          <Row>
            <Col className="p-1"><img src="/static/anonymous/img/pig4.jpeg" className="img-fluid rounded" alt="Pig 4" /></Col>
            <Col className="p-1"><img src="/static/anonymous/img/pig5.jpeg" className="img-fluid rounded" alt="Pig 5" /></Col>
            <Col className="p-1"><img src="/static/anonymous/img/pig6.jpeg" className="img-fluid rounded" alt="Pig 6" /></Col>
          </Row>
          <Row>
            <Col className="p-1"><img src="/static/anonymous/img/pig7.jpeg" className="img-fluid rounded" alt="Pig 7" /></Col>
            <Col className="p-1"><img src="/static/anonymous/img/pig8.jpeg" className="img-fluid rounded" alt="Pig 8" /></Col>
            <Col className="p-1"><img src="/static/anonymous/img/pig9.jpeg" className="img-fluid rounded" alt="Pig 9" /></Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default HomeArt
