import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


const HomeTeam = ({...props}) => {
  return (
    <Container className="px-3 px-md-5 pb-5 pt-3">
      <Row className="helvetica-heading h1 mt-5 px-3 text-left">THE TEAM</Row>
      <Row className="mt-3">
        <Col className="pb-5 pb-md-0" md={4}>
          <img src="/static/anonymous/img/mrs_jones.jpeg" className="img-fluid rounded" alt="Reveal 1" />
          <div className="helvetica-heading h4 mt-3">MRS JONES</div>
          <div className="helvetica-body mt-2">
            Not the better half of <a className="pointer" href="https://tezos-nodes.com/baker/tez-baker" target="_blank" rel="noreferrer">Tez Baker</a>,
            but a half none the less, Mrs Jones has been in the Tezos community
            since the ICO.
          </div>
          <div className="helvetica-body mt-2">
            Through Tez Baker, Mrs Jones has been a public Baker since Cycle 8,
            and there are only a handfull of Bakers left that can say that.
          </div>
          <div className="helvetica-body mt-2">
            Having never been too active or vocal in Tezos discussions, Mrs Jones
            felt now is the time to build a community where ideas could
            perculate, the noise could be filtered, and the cream given time to
            rise to the top.
          </div>
        </Col>
        <Col className="pb-5 pb-md-0" md={4}>
          <img src="/static/anonymous/img/mr_jones.jpeg" className="img-fluid rounded" alt="Reveal 2" />
          <div className="helvetica-heading h4 mt-3">MR JONES</div>
          <div className="helvetica-body mt-2">
            Mr Jones loves art in all its forms. Being a big fan of George
            Orwell's{" "}<a href="/animal-farm">Animal Farm</a>{" "}Mr Jones
            found himself sitting on some great artwork with no real home.
          </div>
          <div className="helvetica-body mt-2">
            One fateful night, sipping on a few local ales, while the Thames
            lapped at the windows, ideas were shared which eventually lead to
            Tezos Farmation as we now know it.
          </div>
          <div className="helvetica-body mt-2">
            If you have a moment drop by
            {" "}<a className="pointer" href="https://www.studioimeus.co.uk" target="_blank" rel="noreferrer">Studio Imeus</a>{" "}
            and check out more from Mr Jones.
          </div>
        </Col>
        <Col md={4}>
          <img src="/static/anonymous/img/mr_pilkington.jpeg" className="img-fluid rounded" alt="Reveal 3" />
          <div className="helvetica-heading h4 mt-3">MR PILKINGTON</div>
          <div className="helvetica-body mt-2">
            An intrepid traveller of the world and technology, Mr Pilkington
            completed the team with his smart contract chops.
          </div>
          <div className="helvetica-body mt-2">
            The combination of a well established career developing tools for
            analysis of genes and spectral information, with a love for all
            things crypto; Mr Pilkington is just waiting for the call up to
            start breeding these wonderfully <span style={{textDecoration: `line-through`}}>flawed</span> human animals.
          </div>
          <div className="helvetica-body mt-2">
            ... as are we!
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default HomeTeam
